<template>
  <div class="row mt-8">

    <div class="row">
      <div class="alineado_btn_tutoriales">
        <button
          @click="$router.push('/').catch(() => {})"
          class="btn_regresar_tutoriales">
          <img
            src="@/assets/images/tutoriales/arrow_back.svg"
            alt=""
          />Regresar
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 mt-12">

        <div class="container-video-tutoriales">
          <iframe
            v-if="hasVideo"
            :title="videoTitle"
            :src="videoUrl"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            class="video_frame_tutoriales"
            allowfullscreen>
          </iframe>

          <div
            class="video_frame_tutoriales notfound-video border d-flex align-items-center justify-content-center bg-grey-light"
            v-else>
            <div>
              <p class="text-xl font-semibold">Sin vídeo</p>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
              </svg>
            </div>
          </div>
        </div>

        <!-- se comenta porque no se utilizaran a peticion de pm -->
        <!-- <div class="flex justify-between control-video-tutoriales p-3">
          <div class="">
            <button @click="beforeVideo()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path d="M9.195 18.44c1.25.713 2.805-.19 2.805-1.629v-2.34l6.945 3.968c1.25.714 2.805-.188 2.805-1.628V8.688c0-1.44-1.555-2.342-2.805-1.628L12 11.03v-2.34c0-1.44-1.555-2.343-2.805-1.629l-7.108 4.062c-1.26.72-1.26 2.536 0 3.256l7.108 4.061z" />
              </svg>
            </button>
            <button @click="playVideo()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path fill-rule="evenodd" d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z" clip-rule="evenodd" />
              </svg>
            </button>
            <button @click="stopVideo()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path fill-rule="evenodd" d="M4.5 7.5a3 3 0 013-3h9a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9z" clip-rule="evenodd" />
              </svg>
            </button>
            <button @click="nextVideo()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path d="M5.055 7.06c-1.25-.714-2.805.189-2.805 1.628v8.123c0 1.44 1.555 2.342 2.805 1.628L12 14.471v2.34c0 1.44 1.555 2.342 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256L14.805 7.06C13.555 6.346 12 7.25 12 8.688v2.34L5.055 7.06z" />
              </svg>
            </button>
            <button @click="pauseVideo()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path fill-rule="evenodd" d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>

          <div class="">
            <button @click="fullScreenVideo()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path fill-rule="evenodd" d="M15 3.75a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V5.56l-3.97 3.97a.75.75 0 11-1.06-1.06l3.97-3.97h-2.69a.75.75 0 01-.75-.75zm-12 0A.75.75 0 013.75 3h4.5a.75.75 0 010 1.5H5.56l3.97 3.97a.75.75 0 01-1.06 1.06L4.5 5.56v2.69a.75.75 0 01-1.5 0v-4.5zm11.47 11.78a.75.75 0 111.06-1.06l3.97 3.97v-2.69a.75.75 0 011.5 0v4.5a.75.75 0 01-.75.75h-4.5a.75.75 0 010-1.5h2.69l-3.97-3.97zm-4.94-1.06a.75.75 0 010 1.06L5.56 19.5h2.69a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v2.69l3.97-3.97a.75.75 0 011.06 0z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div> -->

      </div>

      <div class="col-sm-6">
        <div class="tabla_estilo_tutoriales">

          <div class="row">
            <div class="col-12">
              <h3 class="titulo_video_tutoriales">Nombre del vídeo</h3>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 mb-2 mx-2 -mt-2">
              <input
                @keyup="searchVideo($event)"
                type="text"
                class="vs-input"
                placeholder="Nombre del vídeo"/>
            </div>
          </div>

          <div class="row">
            <div id="playlist-tutoriales" class="col-12">
              <table class="table table-hover">
                <tbody>
                  <tr
                    class="video-tutorial cursor-pointer"
                    @click="sendVideo(video)"
                    v-for="video in videos"
                    :key="video.id">
                    <th scope="row">
                      <img
                        class="mr-2 -ml-4 w-8 h-8"
                        src="@/assets/images/tutoriales/video.png"
                        alt=""
                      />
                    </th>
                    <td>{{ video.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div id="paginacion-tutoriales" class="row">
            <div class="col-12">

              <!-- paginacion -->
              <nav aria-label="Paginacion tutoriales">
                <ul class="pagination justify-content-end">

                  <li class="page-item">
                    <button
                      id="prev-btn-tutoriales"
                      class="page-link"
                      href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                        <path fill-rule="evenodd" d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z" clip-rule="evenodd" />
                      </svg>
                      Anterior
                    </button>
                  </li>

                  <!-- generacion de numero de paginas -->
                  <div
                    id="videos-pages-tutoriales"
                    class="flex">
                    <!-- <li class="page-item active"><a class="page-link" href="1">1</a></li> -->
                    <!-- <li class="page-item"><a class="page-link" href="2">2</a></li> -->
                    <!-- <li class="page-item"><a class="page-link" href="3">3</a></li> -->
                  </div>


                  <li class="page-item">
                    <button
                      id="next-btn-tutoriales"
                      class="page-link">
                      Siguiente
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                        <path fill-rule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </li>

                </ul>
              </nav>

            </div>
          </div>

          <div id="registros-tutoriales" class="row">
            <div class="col-12">
              <!-- contador de registros -->
              <!-- <p>Mostrando registros del 1 al 10 de un total de 20 registros</p> -->
              <p id="registros-tutoriales-msg"></p>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
	data() {
		return  {
      iframe: null,
      player: null,

      timeout: null,
      videos: [], // para procesar
      videosApi: [], // lista inicial
      token: '',
      videoUrl: null,
      videoTitle: null,
      hasVideo: true,
		}
	},
  mounted() {
    this.getApiKey();
    this.getTokenSisec();
    // this.loadVimeo(); // no es necesario
  },
  methods: {
    loadVimeo() {
      this.iframe = document.querySelector('iframe');
      this.player = new Vimeo.Player(this.iframe);

      this.player.on('play', function() { // borrar listener
        // console.log('played the video!');
      });
      this.player.getVideoTitle().then(function(title) { // borrar get
        // console.log('title:', title);
      });
    },
    // metodos usando api de vimeo, se dejan como referencia
    playVideo() {
      this.player.play(); // se puede usar promise
      // console.log( 'Reproduciendo...' );
    },
    stopVideo() {
      this.player.unload();
      // console.log( 'Deteniendo...' );
    },
    pauseVideo() {
      this.player.pause(); // se puede usar promise
      // console.log( 'Pausando...' );
    },
    fullScreenVideo() {
      this.player.requestFullscreen();
      // console.log( 'Pantalla completa' );
    },
    nextVideo() {
      this.player.loadVideo(76979871); // Definir si esta bien
      // console.log( 'Siguiente video' );
    },
    beforeVideo() {
      this.player.loadVideo(383041858); // Definir si esta bien
      // console.log( 'Anterior video' );
    },
    // metodos usando api de vimeo, se dejan como referencia
    searchVideo( event ) {
      /**
       * Buscador con debounce
       */
      let word = event.target.value;

			// reiniciar temporizador si sigue escribiendo
			if(this.timeout) clearTimeout( this.timeout );

      this.timeout = setTimeout(() => {
        // console.log( 'Buscando...', word );

        let videosInProcess = [...this.videosApi]; // lista inicial
        videosInProcess = videosInProcess.filter( (item) => item.name.toLowerCase().includes( word.toLowerCase() )); // buscar
        this.videos = videosInProcess;// inyecta nuevos valores

        setTimeout(() => { // para paginar resultados de la busqueda
          this.paginate(6); // 6 por default segun el req
        }, 100);

      }, 750);

    },
    paginate( perPage ) {
      let prevBtn = document.getElementById(`prev-btn-tutoriales`);
      let pagesBtn = document.getElementById(`videos-pages-tutoriales`);
			let nextBtn = document.getElementById(`next-btn-tutoriales`);
      let videosList = document.querySelectorAll(`.video-tutorial`);

      let videosPerPage = perPage;
      let totalPages = Math.ceil(videosList.length / videosPerPage);
      let currentPage = 1;

      // console.log( prevBtn );
      // console.log( pagesBtn );
      // console.log( nextBtn );
      // console.log( videosList );
      // console.log( videosPerPage );
      // console.log( totalPages );

      let disableButton = (button) => {
        button.classList.add("disabled");
        button.setAttribute("disabled", true);
			};
			let enableButton = (button) => {
        button.classList.remove("disabled");
        button.removeAttribute("disabled");
      };
      // cambia el estado de los botones de navegacion
      let changeNavBtnState = () => {
        currentPage === 1 ? disableButton(prevBtn) : enableButton(prevBtn);
        totalPages === currentPage ? disableButton(nextBtn) : enableButton(nextBtn)
      };
			// crear numeros de pagina dinamicos
			let addPageNumber = (index) => {
				// crea nueva numeracion
				let numberPage = document.createElement("button");
				numberPage.className = `video-page-tutorial page-link`;
				numberPage.innerHTML = index;
				numberPage.setAttribute(`video-page-index`, index);
				pagesBtn.appendChild(numberPage);
			};
			// pagina activa
			const setActivePage = () => {
				document.querySelectorAll(`.video-page-tutorial`).forEach((button) => {
					button.classList.remove("active");
					let pageIndex = Number(button.getAttribute(`video-page-index`));
					if (pageIndex == currentPage) {
						button.classList.add("active");
					}
				});
			};
			// genera y limpia numeracion
			let generatePageNumbers = () => {
				// limpiar numeracion anterior
				pagesBtn.innerHTML = "";
				for (let i = 1; i <= totalPages; i++) {
					addPageNumber(i);
				}
			};

      // para calcular registros
      let prevIdx = 0;
			let nextIdx = 0;
      // pagina actual, maneja visibilidad de registros por pagina
			let setCurrentPage = (pageNum) => {
				currentPage = pageNum;
				setActivePage();
				changeNavBtnState();
				let prevRange = (pageNum - 1) * videosPerPage;
				let currRange = pageNum * videosPerPage;

        // calculo de reigstros e idx
        prevIdx = prevRange + 1;
        if (currRange >= videosList.length ) {
          nextIdx = videosList.length;
        } else {
          nextIdx = currRange;
        }
        // mostrar detalle de registros
        this.setRegistrosDetalle( videosList.length, prevIdx, nextIdx );

				videosList.forEach((item, index) => {
					item.classList.add("hidden");
					if (index >= prevRange && index < currRange) {
						item.classList.remove("hidden");
					}
				});
			};

			prevBtn.addEventListener("click", () => {
				setCurrentPage(currentPage - 1);
				// console.log( 'atras', currentPage );
			});
			nextBtn.addEventListener("click", () => {
				setCurrentPage(currentPage + 1);
				// console.log( 'adelante', currentPage );
			});

			generatePageNumbers();
      setCurrentPage(1);

      // map buttons for click
			document.querySelectorAll(`.video-page-tutorial`).forEach( (button) => {
				const pageIndex = Number(button.getAttribute(`video-page-index`));
				if (pageIndex) {
					button.addEventListener("click", () => {
						setCurrentPage(pageIndex);
					});
				}
			});

      // no hay videos para apginar
			if ( !videosList.length ) {
				// disabled page nav buttons
				prevBtn.setAttribute("disabled", true);
				nextBtn.setAttribute("disabled", true);
			}

    },
    setRegistrosDetalle( countVideos, prevIdx, nextIdx ) {
			let registrosDetalle = document.getElementById(`registros-tutoriales-msg`);
			if ( countVideos > 0) {
				registrosDetalle.innerHTML = `Mostrando registros del ${ prevIdx } al ${ nextIdx } de un total de ${ countVideos } registros`;
			} else {
				registrosDetalle.innerHTML = `Mostrando 0 registros`;
			}
    },
    getApiKey() {
      if (!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
        this.$router.push('/login');
      } else {
        this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
      }
    },
    getTokenSisec() {
      let objRequestToken = {
        strApiKey: this.key,
        strMetodo: 'DameAuthToken',
      }
      this.$axios.post('/', objRequestToken,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then( response => {

          if (!response.data.error) {
            this.token = response.data.access_token;
            this.getVideos(); // procesa videos
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error al obtener token',
              text: response.data.error_description,
              color: 'danger',
              position: 'top-right',
            })
          }

        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        });
    },
    getVideos() {
      let self = this;
      let objRequestTutorial = {
        //"PlatformId": 995, // id para comparador pruebas
         "PlatformId": 907, // id para comparador prod
      }
      let tokenBearer = "Bearer " + this.token;
      this.$axios.post('https://apivalidacionriesgos.sisec.mx/api/Tutorial/GetTutorials', objRequestTutorial, {
        headers: {"Authorization": tokenBearer}
      }) // api tutorials
      .then(resp => {
        if (resp.status == 200) {
          this.videosApi = [...resp.data.Tutorial]; // base inicial
          this.videos = [...resp.data.Tutorial]; // para procesamiento

          // console.log( resp );
          // console.log( this.videos ); // estructura

          // validar si tiene datos
          if (resp.data.Tutorial.length > 0) {

            // si hay videos, se toma el primero para mostrarlo en el iframe al inicio
            this.sendVideo(resp.data.Tutorial[0]);

            return {
              perPage: 6, // cantidad de elementos por pagina
              msg: "Con datos"
            }
          } else {
            return {
              perPage: 0,
              msg: "Sin datos",
            };
          }

        } else {
          this.$vs.notify({
            title: 'Ocurrio un error al obtener los videos',
            text: resp.data.error,
            color: 'danger',
            position: 'top-right'
          });
        }
      }).then( render => { // renderizado
        if (render.perPage) {
          this.paginate(render.perPage);
        }
      })
      .catch(function (error) {
        self.$vs.notify({
          title: 'Ocurrio un error',
          text: error,
          color: 'danger',
          position: 'top-right'
        });
      });
    },
    sendVideo( video ) {
      // console.log( 'Enviando video al iframe...', video );
      this.videoUrl = null; // reinicio

      let iframe = video.iframe;
      let idxStart = iframe.indexOf('src="'); // encontrar indice tag src
      // console.log( 'idxStart', idxStart );

      if (idxStart == -1) { // una valicion
        // console.log( 'No hay url de video' ); // TODO: accion para no dejar iframe en blanco
        this.hasVideo = false;
      } else {
        this.hasVideo = true;

        idxStart = idxStart + 5; // indice real
        let url = iframe.slice(idxStart);
        let idxEnd = url.indexOf('"'); // encontrar " de cierre
        url = url.slice(0, idxEnd); // url final del video
  
        this.videoUrl = url; // para visualizar video
        this.title = video.name; // para attr title
      }
      // console.log( this.videoUrl );
    },
  },
};
</script>

<style>
.alineado_btn_tutoriales {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.btn_regresar_tutoriales {
  width: 150px;
  border-radius: 20px;
  background-color: #033a74;
  color: #fff;
  height: 40px;
  border: none;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
}
.container-video-tutoriales {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.video_frame_tutoriales {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.video_frame_tutoriales.notfound-video {
  text-align: center;
}
.control-video-tutoriales {
  background-color: #fafcff;
}
.control-video-tutoriales button {
  border: none;
  background: none;
  color: gray;
  margin: 0 5px;
}
.tabla_estilo_tutoriales {
  background-color: #fff;
}
.titulo_video_tutoriales {
  background-color: aliceblue;
  color: #12629a;
  font-weight: 600;
  font-size: 1.2rem;
  border-bottom: 3px solid #12629a;
  padding: 1rem 1rem 0.5rem;
}
#playlist-tutoriales table {
  margin-bottom: 0;
}
#playlist-tutoriales table tbody {
  border-top: 2px solid #f7f9fd;
  border-bottom: 2px solid #f7f9fd;
}
#playlist-tutoriales table tbody tr td {
  color: #12629a;
  font-size: 1.2rem;
}
#paginacion-tutoriales {
  background-color: #f1f4ff;
  padding: 1rem;
}
#paginacion-tutoriales .page-link {
  color: #4ed176;
  font-weight: 500;
}
#paginacion-tutoriales .page-link.active {
  background-color: #4ed176;
  border-color: #4ed176;
  color: white;
}
#paginacion-tutoriales .page-link.disabled {
  background-color: #c4dfca;
  border-color: #DEE2E6;
  color: #4ed176;
}
#paginacion-tutoriales .page-link:focus {
  box-shadow: 0 0 0 0.25rem rgba(35, 209, 29, 0.479);
}
#registros-tutoriales {
  background-color: #f1f4ff;
  padding: 1rem;
  text-align: center;
}
</style>